
.moncur-dropdown {
  > li {
    padding: 0;
    position: relative;

    &.dropdown-open {
      > .dropdown-submenu {
        display: block;
      }
    }

    > .dropdown-submenu {
      position: absolute;
      top: 100%;
      z-index: 1000;
      display: none;
    }
  }
}
